<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <button class="btn_sub1" @click="fullScreen = !fullScreen">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="monitor_wrap">
        <div class="monitor_head">
          <h2>불량검출 현황</h2>
          <h2>
            {{ getProductInfo.name + ' - ' + getProductInfo.standard }}
          </h2>
          <span
            >조회시간 :
            {{ getCounterSensor[0].create_time | formatDateHHMMSS }}</span
          >
        </div>

        <div class="monitor_body">
          <div class="monitor_card">
            <h3>진행 수</h3>
            <p>
              {{
                $makeComma(
                  $decimalAdd(
                    $makeNumber(getCounterSensor[0].value),
                    $makeNumber(getCounterSensor[1].value),
                  ),
                )
              }}
            </p>
          </div>
          <div class="monitor_card good_card">
            <h3>양품</h3>
            <p>{{ $makeComma($makeNumber(getCounterSensor[0].value)) }}</p>
          </div>
          <div class="monitor_card bad_card">
            <h3>불량</h3>
            <p>{{ $makeComma($makeNumber(getCounterSensor[1].value)) }}</p>
          </div>
          <div class="monitor_card bad_card">
            <h3>불량률</h3>
            <p>
              {{
                $decimalAdd(
                  $makeNumber(getCounterSensor[0].value),
                  $makeNumber(getCounterSensor[1].value),
                ) > 0
                  ? $makeNumber(
                      $decimalMul(
                        $decimalDiv(
                          $makeNumber(getCounterSensor[1].value),
                          $decimalAdd(
                            $makeNumber(getCounterSensor[0].value),
                            $makeNumber(getCounterSensor[1].value),
                          ),
                        ),
                        100,
                      ),
                    ).toFixed(2)
                  : 0
              }}%
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      counter_interval: null,
      timer: 1,
      fullScreen: false,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      counter_logs: 'getCounterLog',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
      products: 'getProduct',
    }),
    getCounterSensor() {
      const passCountSensors = this.plc_sensor_logs.find(
        x => x.plc_sensor_detail_type_detail == 'pass count',
      );
      const failCountSensors = this.plc_sensor_logs.find(
        x => x.plc_sensor_detail_type_detail == 'fail count',
      );
      return [passCountSensors, failCountSensors];
    },
    getProductInfo() {
      if (this.counter_logs.length < 1) {
        return { name: '', standard: '' };
      } else {
        return this.findInfoFromId(
          this.products,
          this.counter_logs[0].product_id,
        );
      }
    },
  },
  watch: {
    async timer(newValue) {
      if (newValue == 0 && this.tabIndex == 1) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
        await this.FETCH_NO_SPINNER(
          'FETCH_COUNTER_LOG_NOW',
          '금속검출기 점유현황',
        );

        this.timer = 10;
      }
    },
  },
  destroyed() {
    clearInterval(this.counter_interval);
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT', '제품');
    }
    clearInterval(this.counter_interval);
    this.counter_interval = setInterval(() => {
      this.timer -= 1;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
